import { useContext } from "react";
import { Context } from "../componets/context/Context";
import { fetchSSO } from "../helpers/ssoFetch";

export const useSingleSignOn = ({setData}) => {

    const { app } = useContext(Context);

    const loginSignOn = async ({ url, appName, params }) => {

        const paramsSSO = {};
        paramsSSO.params = params;
        paramsSSO.appName = appName;
        paramsSSO.app = app;
        const responseError = {
            "success": false,
            "msg": "invalid"
        }

        const response = await fetchSSO(paramsSSO);

        if (response && response.ok) {
            const body = await response.json();
            if (body && body.success !== undefined) {
                if(setData) {
                    setData({
                        "response" : response,
                        "body" : body
                    });
                }
                return {
                    "response" : response,
                    "body" : body
                };
            } else {
                if(setData) {
                    setData({
                        "response" : response,
                        "body" : responseError
                    });
                }
                return {
                    "response" : response,
                    "body" : responseError
                };
            }
        } else {
            const bodyError = await response.json();
            if(bodyError && bodyError.success !== undefined) {
                if(setData) {
                    setData({
                        "response" : response,
                        "body" : bodyError
                    });
                }
                return {
                    "response" : response,
                    "body" : bodyError
                };
            } else {
                if(setData) {
                    setData({
                        "response" : response,
                        "body" : responseError
                    });
                }
                return {
                    "response" : response,
                    "body" : responseError
                };
            }
        }
    };

    return {
        loginSignOn
    };
}
