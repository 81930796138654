import React, { useState } from 'react';
import { Provider } from "react-redux"; 
import { store } from "./store/store";

import { AppRouter } from './routers/AppRouter';
import { validSSOUrl } from './service/loginServices';
import { SSORoute } from './routers/SSORoute';


export const LoginApp = () => {

    const validation = validSSOUrl();
    const [processSSO, setProcessSSO] = useState(validation);

    if(processSSO) {
        return (
            <Provider store={ store }>
                <SSORoute setProcessSSO={setProcessSSO}/>
            </Provider>
        );
    }

    return (
        <Provider store={ store }>
            <AppRouter/>
        </Provider>
    )
}
