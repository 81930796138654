import axios from "axios";
import { ShowMessageTknExp } from "../componets/showMessage/ShowMessage";

export default async function Apicall({
    method = 'post',
    url,
    data,
    headers = {},
    noAuth,
    app,
    props = {}
}){
    const authHeader = sessionStorage.getItem("userRedirect") && noAuth !== true ? {'Authorization': 'Bearer '.concat(sessionStorage.getItem("userRedirect"))} : {};
    try {
        
        const response = await axios({
            method,
            url,
            data,
            headers : { ...headers, 
                ...authHeader 
            },
            ...props
        });

        return response;

    } catch (error) {
        if (error && error.response && error.response.status === 401) {
            
            if(error.response.data && error.response.data.tkupdt){

                sessionStorage.setItem("userRedirect", error.response.data.tkupdt);
                return await updateTokenAndRetry(url, method, data, headers, error.response.data.tkupdt, noAuth, app, props);
            }else{
                const currentToken = sessionStorage.getItem("userRedirect");
                let sentToken = (noAuth !== true && authHeader['Authorization']) ? authHeader['Authorization'].replace('Bearer ', '') : null;

                if (sentToken == null) {
                    if (data instanceof FormData && data.has('tokenIP4')) {
                        sentToken = data.get('tokenIP4');
                    } else if (typeof data === 'object' && data !== null && 'tokenIP4' in data) {
                        sentToken = data.tokenIP4;
                    } else if (typeof data === 'string' && data.includes('tokenIP4')) {
                        const tokenMatch = data.match(/tokenIP4=([^&]*)/);
                        sentToken = tokenMatch ? tokenMatch[1] : null;
                    }
                }

                if (currentToken && sentToken != null && sentToken !== currentToken) {
                    return await updateTokenAndRetry(url, method, data, headers, currentToken, noAuth, app, props);
                }else{
                    if (!document.querySelector('.swal2-html-container')) {
                        ShowMessageTknExp(app);
                    }
    
                    const loaderWrapper = document.querySelector('[data-testid="wrapper"]');
                    if (loaderWrapper) {
                        loaderWrapper.style.display = 'none';
                    }
                    throw error;
                }
            }

        }else{
            Promise.reject(error);
        }
    }
}

async function updateTokenAndRetry(url, method, data, headers, tkupdt, noAuth, app, props) {
    const authHeader = tkupdt && noAuth !== true ? {'Authorization': `Bearer ${tkupdt}`} : {};
    try {
        
		const newHeaders = {...headers, ...authHeader};

        let newData = data;
        if (typeof newData === 'string' && newData.includes('tokenIP4')) {
            newData = newData.replace(/tokenIP4=([^&]*)/, `tokenIP4=${tkupdt}`);
        } else if (newData instanceof FormData && newData.has('tokenIP4')) {
            newData.set('tokenIP4', tkupdt);
        } else if (typeof newData === 'object' && newData !== null && 'tokenIP4' in newData) {
            newData.tokenIP4 = tkupdt;
        }

        const response = await axios({ method, url, data: newData, headers: newHeaders, ...props });
        return response;
    } catch (error) {

        if (error && error.response && error.response.status === 401) {

            if (!document.querySelector('.swal2-html-container')) {
                ShowMessageTknExp(app);
            }
            const loaderWrapper = document.querySelector('[data-testid="wrapper"]');

            if (loaderWrapper) {
                loaderWrapper.style.display = 'none';
            }

            throw error;
        }else{
            Promise.reject(error);
        }
    }
}

export const createCall = ({base, params}) => {
    let stringParams = "";
    for(const [key,value] of Object.entries(params)){
        if(stringParams !==""){
            stringParams+="&";
        }
        stringParams+= key + "=" + value;
    }
    
    return base + "?" + stringParams;
};