import JSEncrypt from 'jsencrypt';

const KEY_T = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnEsCNE8f/K/xCKm7qUH0
hgdfhT3nH0V0EcdlHkzBU21d7Reryo0Z4DEYMIObKXJbTt9k+KcWJ9Uh65Fw38m6
8qa8HjJqBxhH+mTIG+26skrcb67DKFwyHbybqbTz/I3q1N70S98MRAQRf0pV+tVO
EztR1pk3/vY8W4ZtGo63HTRQyO1cfvUnoaPCDCjgc9imIAc3rIb4voN2m0I7hqef
QJIQqioFkfdxqhJkVcPLN6gJpoM4tKk5wMJIF4LhizUkpJfCfMcKnyZBAd2tZ8tI
0hHNsz0mwcxXJ6jETsWkY+zdAg488L3ZPncQOQ4L+miYJqqto/dKwXSza0+ii6Pa
RwIDAQAB
-----END PUBLIC KEY-----`;

const KEY_P = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAiFi8UODCMbb0+p1u/MRW
01KMlbSml0LLy179k2VOx03xAnE4GgrAnthAHcfbnImxzZsuh+r/TdmDuKqPKLmW
LNI8tmvQDSYCB7M0lfQwP4+nki4jyn5yAAgAkkUP8RkNn49BlSK6O5KqXSgHOCZg
j/rlYIdoMRBRRPGoFozq/iqIFg3nWdwvX2wHhYrOp81oVJlxgOwHp6pr9lsmPfRd
35rRIRt7qpR31gAn4qy+fBrZizWrdYsSfXNWpKtv3KtUr9golm1tzFB5Z8Zxn1zd
cIoQZtOB+WzgcWzGYSpVs5wiHnUmZFSdLoeRrXQRt5z1anFu0avrROtIO5G5wzvl
gQIDAQAB
-----END PUBLIC KEY-----`

export const encryptData = (data, app) => {
    try {

        let KEY = "";

        if (app.url.includes('login-back')) {
            KEY = KEY_P;
        } else {
            KEY = KEY_T;
        }

        const encrypt = new JSEncrypt({
            default_key_size: 2048
        });

        encrypt.setPublicKey(KEY);
        
        const jsonString = JSON.stringify(data);
        
        const encrypted = encrypt.encrypt(jsonString);
        
        if (!encrypted) {
            console.error('Error en el cifrado:', encrypt.getError());
            throw new Error('Error en el cifrado');
        }
        
        return encrypted;
    } catch (error) {
        console.error('Error al cifrar:', error);
        throw error;
    }
};