import React, { useContext } from 'react';
import './sass/loaderIP.css'
import { Context } from '../../context/Context.jsx';

export const LoaderIP = ({ id, text, solid, useImg }) => {

    const solidClass = solid ? " loaderIP4-solid" : "";
    const valImg = useImg !== undefined ? useImg : true;
    const idCmp =  id ? id : "loader";
    const { app } = useContext(Context);
    const baseUrlImg = app.basePathFiles === "" ? "/" : `${app.basePathFiles}`;

    return (
        <div id={idCmp} className={"loaderIP4" + solidClass}>
            <div className="page-loader">
                <div className="page-loader-content" align="center">
                    {valImg && 
                    <div>
                        <img alt="" src= {`${baseUrlImg}img/logo_iproveedor_white.png`} />
                    </div>
                    }
                    {text && 
                    <div className="text-loader">{text}</div>
                    }
                    <div className="lds-ellipsis">
                        <div></div><div></div><div></div><div></div>
                    </div>
                </div>
            </div>
        </div>
    );
}