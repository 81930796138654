import React, { useState, useContext, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import "./loginScreemAfiliado.css";
import * as yup from "yup";
import { useIntl, FormattedMessage } from 'react-intl';
import { TexTranslate } from '../../translations/FlattenMessages';
import LoadingOverlay from 'react-loading-overlay';
import { Timer } from '../ui/Timer.jsx';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Context } from "../context/Context";
import { FormGroup } from "react-bootstrap";
import { useLoginCore, processResponseLoginCore } from '../../hooks/useLoginCore';
import { getNameEmp } from "../../helpers/fetch.js";
import { startLoginAfiliado } from "../../redux/actions/authAfiliados.js";
import { useGuardian } from "../../hooks/useGuardian.js";
import { ShowstrictMessage } from "../showMessage/ShowMessage.js";
import { PasswordField } from "../ui/PasswordField/PasswordField.jsx";

export const LoginScreemAfiliado = ({ nit }) => {
    
    const [showTimer, setShowTimer] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [loadingTime, setLoadingTime] = useState(60);
    const intl = useIntl();
    const dispatch = useDispatch();
    const { app } = useContext(Context);
    const history = useHistory();
    const [valueSelect, setValueSelect] = useState(nit);
    const [valueSelectClient, setValueSelectClient] = useState(null);
    const { loginCore } = useLoginCore();
    const [opciones, setOpciones] = useState([]);
    const { getGuardian } = useGuardian();
    const baseUrl = app.basePathFiles === "" ? "/" : app.basePathFiles;

    useEffect(() => {
        getGuardian("loginAfiliado");
        actualizarGuardian();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


    useEffect(() => {
        const cargarOpciones = async () => {
            const inputAfiliado = document.getElementById("opcAfiliado");
            
            if (inputAfiliado && inputAfiliado.value !== "") {
                const nits = inputAfiliado.value.split(",");

                const respuesta = await getNameEmp({ nits }, app);

                if(respuesta != null && respuesta.success && respuesta.empresas
                    && respuesta.empresas.length > 0) {
                        for(var i = 0; i< respuesta.empresas.length ; i++) {
                            if(respuesta.empresas[i].nitAfiliado === nit) {
                                setValueSelectClient(respuesta.empresas[i].keyClient);
                            }
                        }
                        setOpciones(respuesta.empresas);
                }
            }
        };
        cargarOpciones();
    }, [app]);

    const handleSelectChange = (e) => {
        setValueSelect(e.target.value);
        if (e.target.options[e.target.selectedIndex] 
            && e.target.options[e.target.selectedIndex].dataset
            && e.target.options[e.target.selectedIndex].dataset.keyclient) {
            setValueSelectClient(e.target.options[e.target.selectedIndex].dataset.keyclient);
        }
    };   

    const getRecaptchaToken = async () => {
        try {
            const token = await window.grecaptcha.execute();
            return token;
        } catch (error) {
            console.error('Error al obtener el token de reCAPTCHA:', error);
            return '';
        }
    };

    const actualizarGuardian = () => {
        sessionStorage.setItem("guardiaTimeOut",setTimeout(() => {
            refreshMessage();
        }, 600000)); // 10 minutos para pruebas
    }

    const refreshMessage = () => {
        //revisar por que se refresca estando logueado
        ShowstrictMessage(
            {
                title: TexTranslate(intl, "general.Warning"),
                message: TexTranslate(intl, "login.Due to inactivity and security, the page will automatically refresh"),
                typeMessage: "warning",
                confirmButtonText: TexTranslate(intl, "general.Accept"),
                callbackConfirm: (() => {
                    window.location.reload();
                })
            }
        );
    };

    const formSchema = yup.object({
        username: yup.string().required(<FormattedMessage id={"login.user required"} defaultMessage={"login.user required"} />),
        password: yup.string().required(<FormattedMessage id={"login.password required"} defaultMessage={"login.password required"} />),
    });

        return (
            <Context.Provider value={{ showTimer, setShowTimer }}>
                <LoadingOverlay active={showLoader} spinner text={TexTranslate(intl, "general.loading...", "general.loading...")}>
                    <div className="section-loginAfiliado">
                        <div className="containerAfiliado">
                            <div className="formAfiliado">

                                <h2 className="h2Img">
                                    <img src={`${baseUrl}afiliados/${nit}/img/logo.png`} width="250" alt="logo cliente" style={{ cursor: 'pointer' }} />
                                </h2>

                                {
                                    showTimer ? <div className="divTimerAfiliado"> <Timer loadingTime={loadingTime} /> </div> :
                                        <>
                                            <Formik
                                                initialValues={{ username: "", password: "" }}
                                                validationSchema={formSchema}
                                                onSubmit={async (data, { setSubmitting }) => {
                                                    setShowLoader(true);
                                                    setSubmitting(true);
                                                    const newRecaptchaToken = await getRecaptchaToken();
                                                    const jsonData = {
                                                        "username": data.username,
                                                        "password": data.password,
                                                        "idiomaSelect" : "es",
                                                        "captcha": newRecaptchaToken,
                                                        "keyClient" : valueSelectClient,
                                                        "nit" : nit,
                                                        "nitSelect" : valueSelect,
                                                        setShowLoader,
                                                        TexTranslate,
                                                        intl,
                                                        app,
                                                        setShowTimer,
                                                        setLoadingTime,
                                                        history,
                                                        loginCore,
                                                        processResponseLoginCore
                                                    }
                                                    dispatch(startLoginAfiliado(jsonData));
                                                    setSubmitting(false);
                                                }}
                                            >

                                                {({ isSubmitting, setFieldValue }) => (
                                                    <Form>
                                                        <FormGroup className="inputComponent">
                                                            <Field className="input" id="username" name="username" type="text" placeholder={TexTranslate(intl, "login.user", "login.user")} />
                                                            <ErrorMessage
                                                                name={"username"}
                                                                component="div"
                                                                className="field-error text-danger"
                                                            />
                                                        </FormGroup>

                                                        <FormGroup className="inputComponent">
                                                        <PasswordField
                                                            name="password"
                                                            placeholder={TexTranslate(intl, "login.password", "login.password")}
                                                            >
                                                        </PasswordField>
                                                        </FormGroup>

                                                        {
                                                            opciones.length > 1 &&
                                                            <div className="inputComponent">
                                                                <select
                                                                    className="select"
                                                                    value={valueSelect}
                                                                    id="selectAfiliado"
                                                                    onChange={handleSelectChange}
                                                                >
                                                                    {
                                                                        opciones.map((obj) => (
                                                                            <option 
                                                                                className="option"
                                                                                data-keyClient={obj.keyClient} 
                                                                                value={obj.nitAfiliado} 
                                                                                key={obj.nitAfiliado}>
                                                                                {
                                                                                    obj.razonSocial
                                                                                }
                                                                            </option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        }

                                                        <FormGroup className="inputComponent">
                                                            <button className="envio-login input" type="submit">
                                                                <FormattedMessage id={"login.sign in"} defaultMessage={"login.sign in"} />
                                                            </button>
                                                        </FormGroup>
                                                    </Form>
                                                )}

                                            </Formik>
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </Context.Provider>
        );

};