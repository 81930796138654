import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import { TexTranslate } from '../../translations/FlattenMessages';
import log from "../../images/logo_iproveedor.png";
import './sass/codeLogin.css'
import { Context } from '../context/Context';
import { useTemporayKey } from '../../hooks/useTemporalKey';
import { continueLoginCode } from '../../redux/actions/auth';
import { useLoginCore, processResponseLoginCore } from '../../hooks/useLoginCore';
import { types } from '../../types/types';
import { ShowMessage } from '../showMessage/ShowMessage';

export const CodeLogin = () => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const { app } = useContext(Context);
    const { loginData } = useSelector(state => state.auth);
    const optSize = 4;
    const { validateTemporal} = useTemporayKey();
    const [setShowTimer] = useState(false);
    const [setLoadingTime] = useState(60);
    const { loginCore } = useLoginCore();
    const [showComponent, setShowComponent] = useState(true);
    const [showContinue, setShowContinue] = useState(false);
    const [titleTextNotification, setTitleTextNotification] =  useState("");
    const [textNotification, setTextNotification] = useState(TexTranslate(intl,"login.access_code.access_code_msg_send_code", "login.access_code.access_code_msg_send_code"));
    const [classNotification, setClassNotification] = useState(" alert");

    const formSchema = yup.object({
        code: yup.string().required(<FormattedMessage id={`login.access_code.access_code_required`} defaultMessage={"login.access_code.access_code_required"} />)
            .matches(/^[0-9]+$/,TexTranslate(intl, "login.access_code.access_code_number", "login.access_code.access_code_number"))
            .min(optSize,<FormattedMessage id={`login.access_code.access_code_required`} defaultMessage={"login.access_code.access_code_required"} />)
            .max(optSize,<FormattedMessage id={`login.access_code.access_code_required`} defaultMessage={"login.access_code.access_code_required"} />),
    });

    const loginCode = (data) => ({
        type: types.authLoginCode,
        payload: data
    })

    const handleClose = () => {
        dispatch(loginCode({loginData : undefined}));
        history.push('/'+ app.baseName +'/index.html', {});
    }

    const getRecaptchaToken = async () => {
        try {
          const token = await window.grecaptcha.execute();
          return token;
        } catch (error) {
          console.error('Error al obtener el token de reCAPTCHA:', error);
          return '';
        }
      };

    const handleLoginCode = async (values, { setFieldValue, setSubmitting }) => {
        setSubmitting(true);
        try {
            let codeValue = values.code;

            if (codeValue !== "" ) {
                setShowLoader(true);
                setShowComponent(false); 
                setShowContinue(false);
                setFieldValue('code', "");
                setTitleTextNotification("");
                setClassNotification("alert");
                if(loginData.typeLog === "SSO" && loginData.url && loginData.search) {
                    const urlSSOCode = `${loginData.url}${loginData.search}&code=${codeValue}`;
                    window.location.href = urlSSOCode;
                    return;
                }
                const newRecaptchaToken = await getRecaptchaToken();
                const resp = await validateTemporal(codeValue, loginData, false, newRecaptchaToken);
                const body = await resp.json();
                body.username = loginData.username;
                body.password = loginData.password;
                body.language = loginData.language;
                dispatch(continueLoginCode(body, setShowLoader, TexTranslate, intl, app, setShowTimer, setLoadingTime, history, loginCore, processResponseLoginCore));
            }
        } catch (e) {
            console.error(e);
            setShowLoader(false);
        }
        setSubmitting(false);

    }

    const handleContinueLoginCode = () => {
            setShowComponent(true);
            setShowContinue(false);
            setTextNotification(TexTranslate(intl,"login.access_code.access_code_msg_send_code", "login.access_code.access_code_msg_send_code"));
            setTitleTextNotification("")
            setClassNotification("alert");
            TexTranslate(intl,"login.access_code.access_code_msg_send_code", "login.access_code.access_code_msg_send_code")
        
    }

    useEffect(() => {
        if(loginData && loginData.typeLog === "SSO"
            && loginData.failedCode
        ) {
            ShowMessage(
                {
                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                    message: TexTranslate(intl, "login.access_code.incorrect_msg", "login.access_code.incorrect_msg"),
                    typeMessage: "warning",
                    showCancelButton: false,
                    intl
                }
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginData])
    
    const OtpInputField = ({ field, form, ...props }) => {
        return <OtpInput
            className="inputOTPBox"
            containerStyle="containerInput"
            inputStyle="input"
            focusStyle="focus"
            value={form.values.code}
            onChange={value => form.setFieldValue("code", value)}
            numInputs={optSize}
            separator={<span></span>}
            shouldAutoFocus={true}
            isInputNum={true}
            isInputSecure={false}
            {...props}
        />;
    };

    return (
        <LoadingOverlay active={showLoader} spinner
            text={TexTranslate(intl, "general.loading...", "general.loading...")} >
            <div className="section-codeLogin">
                <div className="contendor">
                    <div className="form">
                        <h2><img src={log} width="250" alt="logo iProveedor" /></h2>
                        <div id="divText" className={classNotification + ` text-justify codeNotification`}>
                            {
                                titleTextNotification && titleTextNotification !== "" && 
                                <h5 className='text-center'>
                                    <FormattedMessage id={titleTextNotification} defaultMessage={titleTextNotification} />
                                </h5>
                            }
                            {
                                textNotification && textNotification !== "" && <FormattedMessage id={textNotification} defaultMessage={textNotification} />
                            }
                        </div>
                        {showContinue && 
                            <Formik>
                                <Form>
                                <Row>
                                    <Col xs={4}>
                                        <button type="button" className="btn btn-outline-danger continue-code-login" onClick={() => handleContinueLoginCode()} >
                                            <FormattedMessage id={`login.access_code.continue`} defaultMessage={"login.access_code.continue"} />
                                        </button>
                                    </Col>
                                </Row>
                                </Form>
                            </Formik>
                        }
                        {showComponent &&
                            <Formik
                                initialValues={{code: ""/*,  captcha: ""*/
                                }}
                                validationSchema={formSchema}
                                onSubmit={handleLoginCode}
                                validateOnMount={true}
                                initialTouched={{ 
                                    "code": true,
                                }}
                            >
                                {({ isSubmitting, values, setFieldValue }) => (
                                    <Form>
                                        <FormGroup className="lastInputBox">
                                        <label htmlFor="code"><FormattedMessage id={`login.access_code.access_code`} defaultMessage={"login.access_code.access_code"} /></label>
                                        <Field id="code" name="code" component={OtpInputField} />
                                            <ErrorMessage
                                                name={"code"}
                                                component="div"
                                                className="field-error text-danger"
                                            />
                                        </FormGroup>
                                        <Row>
                                            <Col xs={4}>
                                                <button type="button" className="btn btn-outline-danger close-code-login" onClick={() => handleClose()} >
                                                    <FormattedMessage id={`recoverPass.Close`} defaultMessage={"recoverPass.Close"} />
                                                </button>
                                            </Col>
                                            <Col xs={8} >
                                                <button disabled={isSubmitting} className="submit-code-login" type="submit" >
                                                    <FormattedMessage id={`login.sign in`} defaultMessage={"login.sign in"} />
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        }
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );

}