import { encryptData } from "../utils/CryptoUtils";


export const fetchLoggin = (data, method = 'POST', app) => {

    const url = `${app.url}/oauth/token`;
    const credenciales = btoa('Vd2t!qCdi$q1'.concat(':').concat('ryNcQ1u#Y0Vo'));

    let params = new URLSearchParams();

    params.set('grant_type', 'password');
    params.set('username', data.username);
    params.set('password', data.password);
    params.set('language', data.language);
    params.set('passwordBcrypt', data.passwordBcrypt);
    params.set('passwordHashBcrypt', data.passwordHashBcrypt);
    params.set('salt', data.salt);
    if(data.captcha){
        params.set('captcha', data.captcha);
    }
    if(data.code){
        params.set('code', data.code);
    }

    if(method === 'GET'){
        return fetch(url);
    }else{
        return fetch(url,{
                method,
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic '.concat(credenciales)
                },
                body:params,
            }
            )
            
    }

}

export const fetchIncorrectLoggin = async (data, app) => {

    const url = `${app.url}/userLogin/validarUsuario`;

    const respLogin = await fetch(url,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"username":data.username}),
        }        
    )

    const dataLogin = await respLogin.json();

    return dataLogin;

}

export const fetchValidateLink = async (app, paramsUrl) => {

    const url = `${app.url}/userLogin/validateLink`;

    var formdata = new FormData();
    formdata.append("paramsUrl", paramsUrl);
    const respLink = await fetch(url,{
            method:'POST',
            body: formdata,
        }        
    )

    const dataLink = await respLink.json();

    return dataLink;
}

export const fetchSendEmailPassword = async (data, app,user) => {

    const url = `${app.url}/userLogin/sendEmailPassword`;
    const urlBase = app.loginFront  + "/index.html";

    const jsonData = {
        username: data.username,
        nitUsuario: data.nit,
        email: data.email,
        language: user.language,
        urlBase: urlBase
    }
    
    const params = encryptData(jsonData, app);
    
    const respEmail = await fetch(url,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ params })
        }
    )

    const dataEmail = await respEmail.json();

    return dataEmail;

}

export const fetchNotifyPasswordChange = async (data, app,user) => {

    const url = `${app.url}/userLogin/notifyPasswordChange`;

    const respPass = await fetch(url,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({"username":data.username, "nitUsuario":data.nitUsuario, "email":data.email, "idAcceso":data.idAcceso,"language":user.language
                , "paramsUrl" : data.paramsUrl}),
        }        
    )

    const dataPass = await respPass.json();

    return dataPass;

}

export const fetchPasswordValidity = async (app, token) => {
    const url = `${app.url}/userLogin/passwordValidity`;    

    const auth = 'Bearer '+token;
    const resp = await fetch(url,
        {
        method: 'POST',
        headers:{
            'Authorization': auth
            },
        }
    );

    const dataResp = await resp.json();

    return dataResp;
    
}

export const fetchNotifyPasswordChangeLogged = async (data, token, app,user) => {

    const url = `${app.url}/userLogin/notifyPasswordChangeLogged`;
    const auth = 'Bearer '+token;
    const respPass = await fetch(url,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': auth
            },
            body: JSON.stringify({"idAcceso":data.newPass, "accesoActual" : data.currentPass,"language":user.language, "passMd5": data.passMd5, "usrEncrypt": data.usrEncrypt}),
        }        
    )

    const dataPass = await respPass.json();

    return dataPass;

}

export const fetchGetBody = async (app, token) => {
    const url = `${app.url}/userLogin/getBody`;    

    const auth = 'Bearer '+token;
    const resp = await fetch(url,
        {
        method: 'POST',
        headers:{
            'Authorization': auth
            },
        }
    );

    const dataResp = await resp.json();

    return dataResp;
    
}

export const getNameEmp = async (data, app) => {
    
    const url = `${app.url}/userLogin/getNameEmpresa`;

    try {
        const respLogin = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "nits": data.nits }),
        });

        const dataLogin = await respLogin.json();
        return dataLogin;
    } catch (error) {
        console.log('Error en getNameEmp:', error);
        throw error;
    }
};

