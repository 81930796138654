import { useEffect, useState } from "react";
import { Context } from '../componets/context/Context';
import { getAppURL } from "./getAppUrl";
import { IntlProvider } from "react-intl";
import Locales, { getLocalesProvider } from "../translations/Locales";
import { FlattenMessages } from "../translations/FlattenMessages";
import { BrowserRouter as Router, Route , Switch, Redirect  } from "react-router-dom";
import { SingleSignOn } from "../componets/sso/SingleSignOn";
import { useSelector } from "react-redux";

export const SSORoute = ({setProcessSSO}) => {
    const { access_token, loginData } = useSelector(state => state.auth);
    const [app, setApp] = useState(getAppURL);
    const locale = Locales(getLocalesProvider('es'));
    const urlRedirect = window.location.hash !== undefined ? window.location.hash.replace(/^#/, '').replace(/\/$/, '') : "";
    const params = window.location.search;

    useEffect(() => {
        if(access_token) {
            setProcessSSO(false);
        } else if(loginData) {
            setProcessSSO(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access_token, loginData])

    const renderSSO = (props) => {
        const appValue = props.match.params.app;
        const url = props.match.url;
        const params = {};

        try {
            const searchParams  = window.location.search.replaceAll("+","%2B");
            const search = new URLSearchParams(searchParams);
            for (const [key, value] of search.entries()) {
                params[key] = value;
            }
          } catch (error) {
            console.error('Error al procesar la URL:', error);
          }

        return (
            <SingleSignOn appValue={appValue} url={url} search={window.location.search} params={params}  />
        );
    }

    return (
        <Context.Provider value={{ app, setApp }} >
            <IntlProvider locale={locale.language} messages={FlattenMessages(locale.messages)} defaultLocale="es" key={'login'}>
                <Router>
                    <div>
                        <Switch>
                            <Route path="/login/:app/sso" component={renderSSO} />
                            <Route path={`/${app.baseName}/:app/sso`} component={renderSSO} />
                            <Route exact path={`/${app.baseName}/codeLogin.html`} />
                            <Route exact path={`/${app.baseNameDashboard}/index.html`} />
                            <Redirect to={{
                                pathname: urlRedirect,
                                search: params
                            }} />
                        </Switch>
                    </div>
                </Router>
            </IntlProvider>
        </Context.Provider>
    )
}