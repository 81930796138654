import React, { useContext, useEffect, useState } from "react"
import { TexTranslate } from "../../translations/FlattenMessages"
import { Context } from "../context/Context";
import { useIntl } from "react-intl";
import { LoaderIP } from "../ui/loaderIP/LoaderIP";
import { useSingleSignOn } from "../../hooks/useSingleSignOn";
import { ShowMessage } from "../showMessage/ShowMessage";
import { useGuardian } from "../../hooks/useGuardian";
import { startLoginSSO, processLoginSSO } from "../../redux/actions/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLoginCore, processResponseLoginCore } from "../../hooks/useLoginCore";
import { useDispatch } from "react-redux";


export const SingleSignOn = ({
    appValue,
    url,
    search,
    params
}) => {

    const { app } = useContext(Context);
    const history = useHistory();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [load, setLoad] = useState(true);
    const [texto, setTexto] = useState(TexTranslate(intl, "general.loading...", "general.loading..."));
    const [dataSign, setDataSign] = useState(null);
    const { dataResGuard, getGuardian } = useGuardian();
    const paramSingleSignOn = {};
    const { loginSignOn } = useSingleSignOn(paramSingleSignOn);
    const { loginCore } = useLoginCore();

    useEffect(() => {
        getGuardian("loginsso");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataResGuard != null) {
            const paramObj = {
                "url": url,
                "appName": appValue,
                "search": search,
                "params": params
            };

            const fnStartLoginSSO = startLoginSSO({
                paramObj,
                TexTranslate,
                intl,
                loginSignOn,
                setLoad,
                setTexto,
                setDataSign
            });
            fnStartLoginSSO();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataResGuard]);

    useEffect(() => {
        if (load === false) {
            let mensaje = TexTranslate(intl, "login.Your request could not be processed and you will be redirected shortly.", "login.Your request could not be processed and you will be redirected shortly.");
            if (dataSign != null) {
                if (dataSign.msg !== "" && dataSign.msg !== "invalid"
                    && dataSign.msg !== "error"
                ) {
                    mensaje = dataSign.msg;
                }
            }
            ShowMessage(
                {
                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                    message: mensaje,
                    typeMessage: "warning",
                    showCancelButton: false,
                    confirmButtonText: TexTranslate(intl, "general.Accept"),
                    callbackConfirm: () => {
                        window.location.replace("/");
                    },
                    intl
                }
            )
            setTimeout(() => {
                window.location.replace("/");
            }, 10000);
        } else {
            if (dataSign != null) {
                if (dataSign.msg === "tk.ok") {
                    const fnProcessLoginSSO = processLoginSSO({
                        "respSSO": dataSign.respSSO,
                        "body": dataSign.body,
                        TexTranslate,
                        intl,
                        app,
                        history,
                        loginCore,
                        loginSignOn,
                        processResponseLoginCore,
                        setLoad,
                        setTexto,
                        setDataSign
                    });

                    dispatch(fnProcessLoginSSO);
                } else if (dataSign.msg === "code" && dataSign.dataLoginCodeSSO) {
                    const fnDataLoginCodeSSO = async (dispatch) => {
                        dispatch(dataSign.dataLoginCodeSSO);
                        history.push(`/${app.baseName}/codeLogin.html`, {});
                    }
                    dispatch(fnDataLoginCodeSSO);
                }

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSign, load]);

    if (load) {
        return (
            <>
                <LoaderIP solid={true} text={texto} />
            </>
        );
    } else {
        return (
            <>
                <div></div>
            </>
        );
    }

}