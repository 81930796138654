import { useContext } from "react";
import { Context } from "../componets/context/Context";
import Apicall from "../api/Apicall";

export const useLandingPage = ({nit}) => {
    const { app } = useContext(Context);

    const paramServicioTercero = new FormData();
    paramServicioTercero.append("opc","LoginView.obtenerServiciosThirdParty");
    paramServicioTercero.append("tokenIP4", sessionStorage.getItem("userRedirect"));
    paramServicioTercero.append("nit", nit);

    const paramCoreSSO = new FormData();
    paramCoreSSO.append("opc","LoginView.obtenerCoreSSO");
    paramCoreSSO.append("tokenIP4", sessionStorage.getItem("userRedirect"));
    paramCoreSSO.append("nit", nit);

    const consultarServiciosTercero = async ({param,setData}) => {
        let response;
        try {

            const result = await Apicall({
                url: `${app.apiCore}/servlet/LoginView`,
                data: param,
                method : 'POST',
                noAuth: true,
                app : app
            });

            if ( result && result.data && result.data.success && result.data.data){
                response = result.data;
            } else {
                response = {"success" : false};
            }
            response && setData(response);
        } catch (error) {
            console.log(error);
            if(error.response && error.response.status === 401) {
                setData({"success" : false, "msg" : "unauthorized"});
            } else {
                setData({"success" : false});
            }
        }
    };

    const consultarCoreSSO = async ({param,setData}) => {
        let response;
        try {

            const result = await Apicall({
                url: `${app.apiCore}/servlet/LoginView`,
                data: param,
                method : 'POST',
                noAuth: true,
                app : app
            });

            if ( result && result.data && result.data.success && result.data.data){
                response = result.data;
            } else {
                console.log(result);
            }
            response && setData(response);
        } catch (error) {
            console.log(error);
            if(error.response && error.response.status === 401) {
                setData({"success" : false, "msg" : "unauthorized"});
            } else {
                setData({"success" : false});
            }
        }
    };

    return {
        paramServicioTercero,
        consultarServiciosTercero,
        paramCoreSSO,
        consultarCoreSSO
    };

}