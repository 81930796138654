import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import { LoginScreemAfiliado } from "../LoginScreemAfiliado";
import DynamicStylesLoader from "../../ui/estilos/DynamicStylesLoader";
import { useHistory } from 'react-router';
import { Context } from "../../context/Context";

export const ContenedorScreemAfiliados = () => {
    const { nit } = useParams();
    const [templateContent, setTemplateContent] = useState("");
    const [htmlLoad, setHtmlLoad] = useState(false);
    const { app } = useContext(Context);
    const history = useHistory();
    const baseUrl = app.basePathFiles === "" ? "/" : app.basePathFiles;

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const response = await fetch(`${baseUrl}afiliados/${nit}/acceso.html`);
                const html = await response.text();
                if (html.indexOf("rout.acceso") >= 0){
                    setTemplateContent(html);
                }else{
                    history.push(`/${app.baseName}/index.html`);
                }
            } catch (error) {
                console.error("Error al cargar la plantilla:", error);
            }
        };

        fetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const container = document.getElementById("rout.acceso");
        if (container ) {
            setHtmlLoad(true);
        }
    }, [templateContent]);

    return (
        <div className="containeLoginAfiliado">
            <DynamicStylesLoader cssPath={`${baseUrl}afiliados/${nit}/estilos.css`}></DynamicStylesLoader>
            <div
                dangerouslySetInnerHTML={{ __html: templateContent }}
            />
            {
                htmlLoad && (ReactDOM.createPortal(<LoginScreemAfiliado nit={nit} />, document.getElementById("rout.acceso")))
            }
        </div>
    );
};
