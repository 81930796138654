import { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import { Context } from "../../context/Context";
import DynamicStylesLoader from "../../ui/estilos/DynamicStylesLoader";
import ReactDOM from "react-dom";
import { LandingPageAfiliado } from "../LandingPageAfiliado";
import { useSelector } from "react-redux";

export const LandingPage = () => {
    const { loginAfiliado } = useSelector(state => state.auth);
    const [templateContent, setTemplateContent] = useState("");
    const history = useHistory();
    const { nit } = useParams();
    const { app } = useContext(Context);
    const [htmlLoad, setHtmlLoad] = useState(false);
    const baseUrl = app.basePathFiles === "" ? "/" : app.basePathFiles;

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const response = await fetch(`${baseUrl}afiliados/${nit}/landingPage.html`);
                const html = await response.text();
                if (html.indexOf("rout.landing") >= 0){
                    setTemplateContent(html);
                }else{
                    history.push(`/${app.baseName}/index.html`);
                }
            } catch (error) {
                console.error("Error al cargar la plantilla:", error);
            }
        };

        fetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const container = document.getElementById("rout.landing");
        if (container ) {
            setHtmlLoad(true);
        }
    }, [templateContent]);

    return (
        <div className="containerLangingAfiliado">
            <DynamicStylesLoader cssPath={`${baseUrl}afiliados/${nit}/landingPage.css`}></DynamicStylesLoader>
            <div
                dangerouslySetInnerHTML={{ __html: templateContent }}
            />
            {
                htmlLoad && (ReactDOM.createPortal(<LandingPageAfiliado nit={nit} nitSelect={loginAfiliado.nitSelect} />, document.getElementById("rout.landing")))
            }
        </div>
    );
};