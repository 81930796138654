import { fetchLoggin, fetchIncorrectLoggin, fetchPasswordValidity, fetchGetBody } from "../../helpers/fetch";
import { types } from "../../types/types";
import { calcMD5 } from "../../helpers/md5";
import { ShowMessage } from "../../componets/showMessage/ShowMessage";
import bcryptjs from "bcryptjs";

export const startLoginAfiliado = (
    {
        username,
        password,
        keyClient,
        nit,
        nitSelect,
        idiomaSelect,
        captcha,
        code,
        setShowLoader,
        TexTranslate,
        intl,
        app,
        setShowTimer,
        setLoadingTime,
        history,
        loginCore,
        processResponseLoginCore
    }) => {

    return async (dispatch) => {

        const idioma = idiomaSelect || 'es';
        const passwordBcrypt = await bcryptjs.hash(password, app.salt);
        let passEncr = code != undefined ? password : calcMD5(password);
        let jsonDataAuth;
        let loginResponse;
        let msgProcessLoginCore;

        let data = {
            username,
            "password": passwordBcrypt,
            "passwordBcrypt": true,
            "captcha": captcha
        };
        if (code !== undefined) {
            data["code"] = code;
        }
        data["language"] = idioma || 'es'
        let resp = await fetchLoggin(data, 'POST', app);

        if (!resp.ok && resp.status === 400) {
            data = {
                username,
                "password": passEncr,
                "passwordBcrypt": false,
                "passwordHashBcrypt": passwordBcrypt,
                "captcha": captcha
            };
            resp = await fetchLoggin(data, 'POST', app);
            if (resp.ok) {
                data.password = passwordBcrypt;
                data.passwordBcrypt = true;
            }
        }

        const body = await resp.json();

        if (!resp.ok && resp.status === 401) {
            setShowLoader(false);
            ShowMessage(
                {
                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                    message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                    typeMessage: "warning",
                    showCancelButton: false,
                    intl
                }
            )
            dispatch(checkingFinish());
        } else if (body.access_token) {

            const respPswVald = await fetchPasswordValidity(app, body.access_token);

            if (respPswVald && respPswVald.tiempoBloqueo < 60) {

                setShowLoader(false);
                setLoadingTime(respPswVald.tiempoBloqueo);
                setShowTimer(true);

            } else {

                if (respPswVald?.success && respPswVald.expired) {
                    setShowLoader(false);
                    dispatch(login({ ...respPswVald, "token": body.access_token, "nit" : nit, "nitSelect" : nitSelect  }));
                    history.push(`/${app.baseName}/changePassMandatory.html`, {});
                    return;
                }

                const respBody = await fetchGetBody(app, body.access_token);
                const tokenJson = respBody.userData;

                if (tokenJson) {
                    const value = {
                        token: body.access_token,
                        idioma: idioma
                    }
                    const dataLoginCore = { "value": value }
                    if (keyClient) {
                        dataLoginCore.keyClient = keyClient;
                    }
                    loginResponse = await loginCore(dataLoginCore);

                    if (loginResponse?.success) {
                        jsonDataAuth = (tokenJson.type_user).toLowerCase();

                        if (jsonDataAuth === 'supplier') {
                            sessionStorage.setItem('userRedirect', body.access_token);
                            sessionStorage.setItem('landing', nit);
                            sessionStorage.setItem('nitSelect', nitSelect);
                            sessionStorage.setItem('idioma', idioma);
                            sessionStorage.setItem('token.type', jsonDataAuth);
                            if(sessionStorage.getItem("guardiaTimeOut")){
                                clearTimeout(sessionStorage.getItem("guardiaTimeOut"));
                                sessionStorage.removeItem("guardiaTimeOut");
                            }
                            dispatch(login({ loginAfiliado: { "access_token" : body.access_token, "nit" : nit, "nitSelect" : nitSelect } }));
                            history.push(`/${app.baseName}/acceso/${nit}/landing`);
                        } else if (jsonDataAuth === 'admin') {
                            sessionStorage.setItem('userRedirect', body.access_token);
                            sessionStorage.setItem('landingAdmin', nit);
                            sessionStorage.setItem('nitSelect', nitSelect);
                            sessionStorage.setItem('idioma', idioma);
                            sessionStorage.setItem('token.type', jsonDataAuth);
                            dispatch(login({ "access_token" : body.access_token }));
                        } else if (jsonDataAuth === "funder") {
                            window.location.href = `${app.apiCore}/Funder.jsp?lang_user=${idioma || 'es'}`
                        } else {
                            setShowLoader(false);
                            console.log("typeUser.error");
                        }


                    } else {
                        msgProcessLoginCore = processResponseLoginCore({
                            "msg": loginResponse.msg,
                            "msgParams": loginResponse,
                            "TexTranslate": TexTranslate,
                            "intl": intl
                        });
                        setShowLoader(false);

                        if (msgProcessLoginCore) {
                            ShowMessage(
                                {
                                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                    message: msgProcessLoginCore,
                                    typeMessage: "warning",
                                    showCancelButton: false,
                                    intl
                                }
                            );
                        } else {
                            ShowMessage(
                                {
                                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                    message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                                    typeMessage: "warning",
                                    showCancelButton: false,
                                    intl
                                }
                            );
                        }
                        dispatch(checkingFinish());
                    }


                } else {
                    setShowLoader(false);
                }
            }

        } else {
            const respLogin = await fetchIncorrectLoggin(data, app);

            if (respLogin?.success && respLogin.intentosLogueo < 5) {
                setShowLoader(false);
                ShowMessage(
                    {
                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                        message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                        typeMessage: "warning",
                        showCancelButton: false,
                        intl
                    }
                )
                dispatch(checkingFinish());
            } else if (respLogin && !respLogin.success 
                && respLogin.intentosLogueo && respLogin.intentosLogueo >= 5) {
                setShowLoader(false);
                //setLoadingTime(Math.floor((respLoginAttempts.tiempoBloqueo/1000) % 60));
                setLoadingTime(60);
                setShowTimer(true);
            } else {
                setShowLoader(false);
                ShowMessage(
                    {
                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                        message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                        typeMessage: "warning",
                        showCancelButton: false,
                        intl
                    }
                )
                dispatch(checkingFinish());
            }
        }
    }

}


const checkingFinish = () => ({
    type: types.authCheckingFinish
})

const login = (user) => ({
    type: types.authLogin,
    payload: user
})
