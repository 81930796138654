import React, { useEffect } from "react";

const DynamicStylesLoader = ({ cssPath }) => {
    useEffect(() => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = cssPath;
        document.head.appendChild(link);

        // Cleanup: remover el link cuando el componente se desmonte o cssPath cambie
        return () => {
            document.head.removeChild(link);
        };
    }, [cssPath]); // Cambia dinámicamente cuando cambia cssPath

    return null; // Este componente solo gestiona la carga del CSS
};

export default DynamicStylesLoader;
