import React, { useEffect, useRef, useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import './PasswordField.css';
import { OverlayTrigger } from 'react-bootstrap';

export const PasswordField = ({
    name,
    placeholder,
    maxLength,
    size,
    tooltip
}) => {

    const [showPassword, setShowPassword] = useState(false);
    const [isEdge, setIsEdge] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        setIsEdge(/Edg/.test(navigator.userAgent));
    }, []);

    const setCursorAtEnd = () => {
        const inputEl = inputRef.current;
        if (inputEl) {
            inputEl.focus();
            const length = inputEl.value.length;
            inputEl.setSelectionRange(length, length);
        }
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        setShowPassword(true);
        setCursorAtEnd();
    };

    const handleMouseUp = () => {
        setShowPassword(false);
        setTimeout(setCursorAtEnd, 0);
    };

    const handleFocus = () => {
        setShowPassword(false);
    };

    const handleInput = (e) => {

        if (isEdge && e.target.value.length === 1) { // Creado para quitar el ojito automático que sale en el navegador Edge para los campos password
            setShowPassword(true);
        }
        setTimeout(() => setShowPassword(false), 0);
    };

    return (
        <div className="inputBox">
            <div className="input-container">
                {tooltip ? (
                    <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={tooltip}>
                        <Field
                            className="input"
                            id={name}
                            name={name}
                            type={showPassword ? 'text' : 'password'}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            size={size}
                            onFocus={handleFocus}
                            onInput={handleInput}
                            innerRef={inputRef}
                        />
                    </OverlayTrigger>
                ) : (
                    <Field
                        className="input"
                        id={name}
                        name={name}
                        type={showPassword ? 'text' : 'password'}
                        placeholder={placeholder}
                        onFocus={handleFocus}
                        onInput={handleInput}
                        innerRef={inputRef}
                    />
                )}
                <span
                    className="password-toggle"
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                >
                    <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`} />
                </span>
            </div>
            <ErrorMessage name={name} component="div" className="field-error text-danger" />
        </div>
    );

};