import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from "react-redux";

import {
    BrowserRouter as Router,
    Switch,
    Redirect
} from "react-router-dom";
import { LoginScreem } from '../componets/auth/LoginScreem';
import { DashboardScreem } from '../componets/user/DashboardScreem';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { Context } from '../componets/context/Context';
import { FlattenMessages } from '../translations/FlattenMessages';
import { IntlProvider } from 'react-intl';
import Locales, { getLocalesProvider } from '../translations/Locales';
import { RecoverPassword } from '../componets/auth/RecoverPassword';
import { ChangePassword } from '../componets/auth/ChangePassword';
import { ChangePasswordMandatory } from '../componets/changePassword/ChangePasswordMandatory';
import { CodeLogin } from '../componets/codeLogin/CodeLogin';
import { getAppURL } from "./getAppUrl";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ContenedorScreemAfiliados } from '../componets/afiliados/contenedorScreemAfiliados/ContenedorScreemAfiliados';
import { LandingPage } from '../componets/afiliados/landingPage/LandingPage';
import { types } from '../types/types';
import { validAfiliadoAccesoUrl } from '../service/loginServices';

export const AppRouter = () => {

    const { checking, access_token, token, expired, loginData, loginAfiliado } = useSelector(state => state.auth);

    const [user, setUser] = useState({
        language: sessionStorage.getItem('idioma') || 'es'
    });
    const [redirect, setRedirect] = useState(null);

    const dispatch = useDispatch();

    const login = (user) => ({
        type: types.authLogin,
        payload: user
    })

    const [app, setApp] = useState(getAppURL);

    const onVerify = useCallback((captcha) => {
        sessionStorage.setItem('captcha', captcha);
    }, []);

    useEffect(() => {
    }, [checking])
 
    const locale = Locales(getLocalesProvider(user.language));
    const langChange = user.language
    
    if((loginAfiliado === undefined || loginAfiliado === null)
        && sessionStorage.getItem("userRedirect") 
        && sessionStorage.getItem("landing")) {
        if(sessionStorage.getItem("guardiaTimeOut")){
            clearTimeout(sessionStorage.getItem("guardiaTimeOut"));
            sessionStorage.removeItem("guardiaTimeOut");
        }
        setRedirect(`/${app.baseName}/acceso/${sessionStorage.getItem("landing")}/landing`);
        dispatch(login({"loginAfiliado" : { "access_token" : sessionStorage.getItem("userRedirect"), "nit" : sessionStorage.getItem("landing"), "nitSelect" : sessionStorage.getItem("nitSelect")}}));
        return (<></>);
    } else if(redirect != null
        && sessionStorage.getItem("userRedirect") 
        && sessionStorage.getItem("landingAdmin")
    ) {
        setRedirect(null);
    }

    if (validAfiliadoAccesoUrl()) {
        const urlRedirect = window.location.hash !== undefined ? window.location.hash.replace(/^#/, '').replace(/\/$/, '') : "";
        setRedirect(`/${app.baseName}${urlRedirect}`);
        window.location.hash = "";
        return (<></>);
    }

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6Ld8DqsUAAAAAOGuDjOCS_KSqjYZMzCiGb7U6VQH"
            language={langChange}
            container={{
                element: "[required_id_or_htmlelement]",
                parameters: {
                    badge: 'bottomleft',
                }
            }}
        >
            <GoogleReCaptcha
                onVerify={onVerify}
            />
            <Context.Provider value={{ user, setUser, app, setApp }} >
                <IntlProvider locale={locale.language} messages={FlattenMessages(locale.messages)} defaultLocale="es" key={'login'}>
                    <Router >
                        <div>
                            <Switch>
                                <PublicRoute exact path={`/${app.baseName}/index.html`} component={LoginScreem} redirect={redirect} isAuthenticated={!!access_token || !!loginAfiliado} />
                                <PublicRoute exact path={`/${app.baseName}/recover.html`} component={RecoverPassword} isAuthenticated={!!access_token} />
                                <PublicRoute exact path={`/${app.baseName}/changePass.html`} component={ChangePassword} isAuthenticated={!!access_token} />
                                <PublicRoute exact path={`/${app.baseName}/codeLogin.html`} component={CodeLogin} isAuthenticated={!loginData} />
                                <PublicRoute exact path={`/${app.baseName}/acceso/:nit`} component={ContenedorScreemAfiliados} redirect={`/${app.baseNameDashboard}/index.html`} isAuthenticated={!!access_token} />
                                <PrivateRoute exact path={`/${app.baseName}/acceso/:nit/landing`} component={LandingPage} isAuthenticated={!!loginAfiliado} />
                                <PrivateRoute exact path={`/${app.baseName}/changePassMandatory.html`} component={ChangePasswordMandatory} redirect={`/${app.baseName}/index.html`} isAuthenticated={!!expired && !!token} />
                                <PrivateRoute exact path={`/${app.baseNameDashboard}/index.html`} component={DashboardScreem} redirect={`/${app.baseName}/index.html`} isAuthenticated={!!access_token} />
                                <Redirect to={redirect === null ? `/${app.baseNameDashboard}/index.html`: `${redirect}`} />
                            </Switch>
                        </div>
                    </Router>
                </IntlProvider>
            </Context.Provider>
        </GoogleReCaptchaProvider>
    )
}
