
export const validSSOUrl = () => {
    const patronBase = /^#\/login(-[a-z]+)?\/.*\/sso(\/)?/;
    const patronParams = /\?k=(.*)/;
    const patronPathBase = /^\/login(-[a-z]+)?\/.*\/sso(\/)?/;
    const patronPathParams = /\?k=(.*)/;

    if( patronBase.test(window.location.hash) && patronParams.test(window.location.search)) {
        return true;
    }
    if( patronPathBase.test(window.location.pathname) && patronPathParams.test(window.location.search)) {
        return true;
    }
    return false;
}

export const validAfiliadoAccesoUrl = () => {
    const patronBase = /^#\/acceso\/.*(\/)?/;

    if( patronBase.test(window.location.hash)) {
        return true;
    }
    return false;
} 

export const redirecInvalitUser = (app, nit) => {
    let url;
    if (app) {
        url = app.loginFront + "/index.html?end=true";
    }
    if (nit) {
        url = app.loginFront + "/acceso/" + nit;
    }
    if (url) {
        window.location.href = url;
    }
}

export const InitialValuesUser = () =>{

    sessionStorage.removeItem( 'userRedirect' );
    sessionStorage.removeItem( 'login' );
    sessionStorage.removeItem( 'email' );
    sessionStorage.removeItem( 'idioma' );
    sessionStorage.removeItem( 'token.type' );
    sessionStorage.removeItem( 'token' );
    sessionStorage.removeItem( 'userData' );
    sessionStorage.removeItem( 'isAuthenticated' );
    sessionStorage.removeItem( 'landing' );
    sessionStorage.removeItem( 'landingAdmin' );
    sessionStorage.removeItem( 'nitSelect' );
}
