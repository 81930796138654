
export const fetchSSO = async (
    {
        params,
        appName,
        app,
        method = 'GET',
        body,
        headers
    }
) => {

    const url = new URL(`${app.url}/login/${appName}/sso`);
    if (typeof params === 'object') {
        url.search = new URLSearchParams(params);
    }
    const fetchData = {};
    fetchData.method = method;
    if (typeof headers === 'object') {
        fetchData.headers = headers;
    } else {
        fetchData.headers = {};
    }
    if (body) {
        fetchData.body = body;
    }
    if (method === 'GET') {
        return fetch(url.href);
    } else {
        return fetch(url.href, fetchData);
    }
}
