import "./LandingPageAfiliado.css";
import { useContext, useEffect, useState } from "react";
import { useLandingPage } from "../../hooks/useLandingPage.js";
import logoIProveedor from "../../images/logo_iproveedor.png"
import { Context } from "../context/Context.jsx";
import { LoaderIP } from "../ui/loaderIP/LoaderIP.jsx";
import { TexTranslate } from '../../translations/FlattenMessages';
import { useIntl } from "react-intl";

export const LandingPageAfiliado = ({ nit, nitSelect }) => {

    const {paramServicioTercero, consultarServiciosTercero, paramCoreSSO, consultarCoreSSO} = useLandingPage({"nit" : nitSelect});
    const [datosServicio, setDatosServicio] = useState(null);
    const [datosCoreSSO, setDatosCoreSSO] = useState();
    const [options, setOptions] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [openTabs, setOpenTabs] = useState({});
    const intl = useIntl();
    const tituloHtml = document.getElementById("tittleLanding");
    const { app } = useContext(Context);
    const baseUrlImg = app.basePathFiles === "" ? "" : "../../";
    const baseUrl = app.basePathFiles === "" ? "/" : app.basePathFiles;
    const logoImagen = nit ? `${baseUrl}afiliados/${nit}/img/logo.png` : "";

    if (tituloHtml != null){
        document.title = tituloHtml.value;
    }

    useEffect(() => {
        const loadServices = async () => {
            try {
                await new Promise((resolve) => {
                    consultarServiciosTercero({
                        "param": paramServicioTercero, 
                        "setData": (data) => {
                            setDatosServicio(data);
                            resolve();
                        }
                    });
                });
                
                await new Promise((resolve) => {
                    consultarCoreSSO({
                        "param": paramCoreSSO, 
                        "setData": (data) => {
                            setDatosCoreSSO(data);
                            resolve();
                        }
                    });
                });
                setIsLoading(false);
            } catch (error) {
                console.error("Error cargando servicios:", error);
                setIsLoading(false);
            }
        };

        loadServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        
        if (datosServicio != null && datosServicio.data && Array.isArray(datosServicio.data)
            && datosServicio.data.length > 0) {

            const updatedOptions = [];

            for (let i = 0; i < datosServicio.data.length; i++) {
                const service = datosServicio.data[i];
                if (service && service.url) {
                    const opcion = {};

                    opcion.logo = service.img;
                    opcion.name = service.label;
                    opcion.description = service.name;
                    opcion.link = service.url;
                    opcion.id = service.id;
                    opcion.tooltip = service.tooltip;

                    updatedOptions.push(opcion);
                }
            }

            if (datosCoreSSO != null && datosCoreSSO.data && Array.isArray(datosCoreSSO.data)){
                for (let i = 0; i < datosCoreSSO.data.length; i++) {
                    const coreSSO = datosCoreSSO.data[i];
                    if (coreSSO && coreSSO.url) {
                        const opcion = {};
    
                        opcion.logo = baseUrlImg + logoIProveedor;
                        opcion.name = coreSSO.label;
                        opcion.description = coreSSO.name;
                        opcion.link = coreSSO.url;
                        opcion.id = "ip4";
                        opcion.tooltip = "De clic aquí para consultar sus certificados tributarios, facturas contabilizadas y el detalle de sus pagos.";
    
                        updatedOptions.push(opcion);
                    }
                }
            }
            setOptions(updatedOptions);
        }else if (datosServicio != null){
            if(datosServicio.msg === "unauthorized") {
                setIsLoading(false);
            } else {
                window.location.replace(`${app.apiCore}/Supplier.jsp?lang_user=es#`);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datosServicio, datosCoreSSO]);

    const handleOpenTab = (option) => {
        if (!option.link) return;
        
        const existingTab = openTabs[option.id];
        if (existingTab && !existingTab.closed) {
            existingTab.location.href = option.link;
            existingTab.focus();
        } else {
            const newTab = window.open(option.link, "_blank");
            setOpenTabs((prevTabs) => ({
                ...prevTabs,
                [option.id]: newTab,
            }));
        }
    };

    if (isLoading) {
        return <LoaderIP solid={true} text={TexTranslate(intl, "general.Loading services...")} />;
    }

    if(!datosServicio || !datosServicio.data
        || datosServicio.data.length === 0 ) {
        return(<></>);
    }

    return (
        <div className="landing-container">
            <h2><img className="logo-landing" src={logoImagen} width="250" alt="logo" /></h2>
            <div className="landing-background">
                <div className="landing-left"></div>
                <div className="landing-right"></div>
            </div>
            <div className="landing-title">
                <p>Seleccione el servicio</p>
                <p>al que desea acceder</p>
            </div>
            <div className="landing-content">
                <div className="landing-options">
                    {options && options.map((option, index) => (
                        <div key={index} className="landing-card-wrapper">

                            <div
                                className={`landing-card tooltip-container`}                                
                                onClick={() => {
                                    if (option.link) {
                                        window.open(option.link, '_self');
                                    }
                                }}
                            >
                                <img
                                    src={option.logo}
                                    alt={option.name}
                                    className="landing-logo"
                                />
                                <div className="landing-description">
                                    {option.description}
                                </div>
                                <span className="tooltip">{option.tooltip}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};