import { InitialValuesUser, redirecInvalitUser } from '../../service/loginServices';
import { TexTranslate } from '../../translations/FlattenMessages';

export const ShowMessage = (
    {
        title, 
        message, 
        typeMessage = 'success', 
        showCancelButton = false,
        intl,
        confirmButtonText =  TexTranslate(intl, "general.Accept", "general.Accept"),
        cancelButtonText = 'Cancelar',
        callbackConfirm,
        html
    }) =>{
    
    const Swal = require('sweetalert2');
    
    Swal.fire({
        title,
        text: message,
        icon: typeMessage,
        showCancelButton,
        confirmButtonText,
        cancelButtonText,
        html
    })
    .then((result) =>{
        if( result.isConfirmed ){
            if( callbackConfirm ){
                callbackConfirm();
            }
        }
    });

    return Swal;
}

export const ShowstrictMessage = (
    {
        title, 
        message, 
        typeMessage = 'success', 
        showCancelButton = false,
        intl,
        confirmButtonText =  TexTranslate(intl, "general.Accept", "general.Accept"),
        cancelButtonText = 'Cancelar',
        callbackConfirm,
        html
    }) =>{
    
    const Swal = require('sweetalert2');
    
    Swal.fire({
        title,
        text: message,
        icon: typeMessage,
        showCancelButton,
        confirmButtonText,
        cancelButtonText,
        html,
        backdrop:'static',
        allowOutsideClick: false
    })
    .then((result) =>{
        if( result.isConfirmed ){
            if( callbackConfirm ){
                callbackConfirm();
            }
        }
    });

    return Swal;
}

export const ShowMessageTknExp = (app, data) => {

    const idioma = (data && data.idioma) || sessionStorage.getItem("idioma") || 'es';
    var title;
    var message;
    var confirmButtonText;
    const nit = sessionStorage.getItem("landing");

    switch (idioma) {

        case "es":
            title = "Advertencia";
            message = "Su sesión ha caducado, Usted debe iniciar sesion nuevamente";
            confirmButtonText = "Aceptar";
            break;

        case "en":
            title = "Warning";
            message = "Your session has expired, you must login again";
            confirmButtonText = "Accept";
            break;

        case "pt":
            title = "Aviso";
            message = "A sua sessão expirou, é necessário iniciar novamente";
            confirmButtonText = "Aceitar";
            break;

        default:
            title = "Advertencia";
            message = "Su sesión ha caducado, Usted debe iniciar sesion nuevamente";
            confirmButtonText = "Aceptar";
            break;
    }

    InitialValuesUser();

    ShowMessage(
        {
            title: title,
            message: message,
            typeMessage: "warning",
            showCancelButton: false,
            confirmButtonText: confirmButtonText,
            callbackConfirm: () => {
                redirecInvalitUser(app, nit);
            },
        }
    );
} 
